import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect} from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;
export const StyledButton = styled.button`
  font-family: "coder";
  height: 115px;
  border: 4px solid;
  border-color: black;
  background-color: transparent;
  width:  auto;
  min-width: 100;
  cursor: pointer;
  transition: 0.8s;
  :hover{
    transform: scale(0.98);
    background: transparent;
    
    background-position: right center;
    color: #fff; 
    text-decoration: none; 
    letter-spacing: 1px;
    
  }
`;

export const StyledRoundButton = styled.button`
  border: none;
  border-radius: 50px;
  background-color: transparent;
  font-weight: bold;
  font-size: 25px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  :hover{
    opacity: 0.9;
    transform: scale(1.2);
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
color: #2ca5ff;
text-decoration: none;
:hover{
  opacity: 0.9;
  color: #2ca5ff;
}
`;



function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  //const [whitelisted, setwhitelisted] = useState(false);
  const [paused, setPaused] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    FREE_WEI_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  


  const getPaused = async () => {
    if (blockchain.smartContract !== null){
    let res = await blockchain.smartContract.methods
    .paused()
    .call();
    console.log(res);
    setPaused(res);}
  };
  
  /** 
  const addresses = whitelist
  const leaves = addresses.map(x => keccak256(x))
  const tree = new MerkleTree(leaves, keccak256, {sortPairs: true})
  const buf2hex = x => '0x' + x.toString('hex')

  console.log(buf2hex(tree.getRoot()))

  const leaf = keccak256(blockchain.account)//blockchain.account
  const proof = tree.getProof(leaf).map(x => buf2hex(x.data))
  console.log(proof)
  // Test after build, more addresses
    const getWhitelist = async () => {
    if (blockchain.smartContract !== null){
    let res = await blockchain.smartContract.methods
    .whitelistMintEnabled()
    .call();
    console.log(res);
    setwhitelisted(res);}
  };*/
  
  const ClaimNFT = () => {
    let cost = CONFIG.WEI_COST;

    let gasLimit = CONFIG.GAS_LIMIT;
    if (paused == true) {
      setFeedback("Contract is Paused");
      return;
    }
    /**
    if (whitelisted == true && proof == "") {
      gasLimit = 1300000000;
      alert("You are not on the Whitelist");
    }
        if (whitelisted == true) {
      cost = CONFIG.FREE_WEI_COST;
    }*/
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit + mintAmount * 2800);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Something Went Wrong");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Thank you for minting`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
      
  };
 
  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
        
    }
  };


  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };
 
  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
   
  }, [blockchain.account]);
  
  //getWhitelist();
  getPaused();

  return (
    
      <s.Container
        flex={1}
        ai={"center"} jc={"center"} style={{
          
        }}
        
      >
        
        
        {Number(data.totalSupply) >= (CONFIG.MAX_SUPPLY)? (
              <>
                <s.Container style={{
                    position: "absolute",
  
  width: "40%",
  height: "60%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(255, 255, 255, 0.05)",
  boxshadow: "0 15px 35px rgba(0,0,0,0.2)",
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  borderRadius: "30px",
  color: "azure",
  zIndex: "1",
  fontWeight: "400",
  letterSpacing: "1px",
  textDecoration: "none",
  overflow: "hidden" ,
  transition: "0.5s",
  backdropFilter: "blur(20px)",
  fontSize:"25px"
                         }}
                >
                <s.TextTitle
                  style={{zIndex:1, textAlign: "center", textShadow: "1px 1px 6px #000000, 1px 1px 10px #000000, 1px 1px 15px #000000",
                  color: "#ffffff", }}
                >
                  All {CONFIG.NFT_NAME} have been minted. 
                </s.TextTitle>
                <s.TextDescription
                  style={{ zIndex:1,textAlign: "center", textShadow: "1px 1px 6px #000000, 1px 1px 10px #000000, 1px 1px 15px #000000",
                  color: "#ffffff", }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                
                <StyledLink
                style={{ fontSize:"24px", zIndex:1,textAlign: "center",textShadow: "1px 1px 6px #000000, 1px 1px 10px #000000, 1px 1px 15px #000000",
                 }} target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
                </s.Container>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  
                  <s.Container ai={"center"} jc={"center"}fd={"row"} >
                   <s.SpacerMedium /><s.SpacerMedium />
                    <StyledButton
                    style={{
                      position: "absolute",
    width: "25%",
    height: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "rgba(255, 255, 255, 0.05)",
    boxshadow: "0 15px 35px rgba(0,0,0,0.2)",
    borderTop: "2px solid rgba(255, 255, 255, 0.1)",
    borderBottom: "2px solid rgba(255, 255, 255, 0.1)",
    borderRadius: "30px",
    color: "azure",
    zIndex: "1",
    fontWeight: "400",
    letterSpacing: "1px",
    textDecoration: "none",
    overflow: "hidden" ,
    transition: "0.5s",
    backdropFilter: "blur(4px)",
    fontSize:"5vmin",
    textShadow: "1px 1px 6px #000000, 1px 1px 10px #000000, 1px 1px 15px #000000, 1px 1px 35px #000000",
    
                           }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      Connect
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        
                        <s.TextDescription
                          style={{
                            position:"absolute",
                            top:"5%",
                            right: "5%",
                            zIndex:1,
                            textAlign: "center",
                            textShadow: "1px 1px 6px #000000, 1px 1px 10px #000000, 1px 1px 15px #000000",
                            color: "#ffffff",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                    
                  </s.Container>
                ) : ( 
                  
                  < >
                  <s.Container
                  style={{
                    position: "absolute",
  
  width: "50%",
  height: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(255, 255, 255, 0.05)",
  boxshadow: "0 15px 35px rgba(0,0,0,0.2)",
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  borderRadius: "30px",
  color: "azure",
  zIndex: "1",
  fontWeight: "400",
  letterSpacing: "1px",
  textDecoration: "none",
  overflow: "hidden" ,
  transition: "0.5s",
  backdropFilter: "blur(20px)",
  fontSize:"25px"
                         }}>
                          
                  <s.SpacerMedium />
                  
                  <s.SpacerMedium /><s.SpacerMedium />

                    
                    
                    <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                zIndex:1,
                textShadow: "1px 1px 6px #000000, 1px 1px 10px #000000, 1px 1px 15px #000000",
                        color: "#ffffff",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.SpacerSmall />
            <s.Container  ai={"center"} jc={"center"} fd={"row"}>
                  
                  <StyledRoundButton
                    
                    style={{ lineHeight: 0.4,zIndex:1 }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                   -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      fontWeight: "bold",
                      zIndex:1,
                      textAlign: "center",
                      textShadow: "1px 1px 6px #000000, 1px 1px 10px #000000, 1px 1px 15px #000000",
                    color: "#ffffff",
                    }}
                  >
                    {mintAmount}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                  style={{zIndex:1}}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    + 
                  </StyledRoundButton>
                 
                </s.Container>
            <s.TextDescription
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        zIndex:1,
                        textShadow: "1px 1px 6px #000000, 1px 1px 10px #000000, 1px 1px 15px #000000",
                        color: "#ffffff",
                        padding:"15px",
                      }}
                    >
                    {feedback}
                    </s.TextDescription>
            <s.Container ai={"center"} jc={"center"} fd={"column"}>
            <s.SpacerSmall />
             <StyledButton
                      style={{
          border: "4px solid",
          borderColor:"black",                       
          fontSize:"5vmin",
          backgroundImage: "linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%)",
          margin: "10px",
          padding: "15px 45px",
          textAlign: "center",
          textTransform: "uppercase",
          transition: "0.5s",
          backgroundSize: "200% auto",
          color: "black",          
          boxShadow: "0 0 5px #eee",
          borderRadius: "10px",
          display: "block",
       
                        zIndex:1, }}
                        disabled={claimingNft ? 1 : 0}

                        onClick={(e) => {
                          e.preventDefault();
                          ClaimNFT();
                          getData();
                        }}
                      >
                        {claimingNft ? "Minting" : "Mint"}
                      </StyledButton> 
                      </s.Container>
                      <s.TextTitle
                  style={{fontSize:"20px",marginInline: "5px", zIndex:1, textAlign: "center", textShadow: "1px 1px 6px #000000, 1px 1px 10px #000000, 1px 1px 15px #000000",
                  color: "#ffffff", }}
                >
                  Mint price is {CONFIG.DISPLAY_COST} Eth. 
                </s.TextTitle>
                <s.SpacerLarge />
                <s.SpacerLarge />
                      </s.Container>

                  </>
                )}
                
              </>
            )}

      </s.Container>
  );
}

export default App;
